<template>
  <HourglassLoader :loading="loading">
    <section>
      <div class="d-flex justify-content-between align-items-center py-3">
        <div class="d-block">
          <h2 class="h4">Withdrawal Transaction Details</h2>
        </div>

        <div class="btn-toolbar">
          <button type="button" class="btn btn-sm btn-outline-primary" @click="RefreshData">Reload</button>
        </div>
      </div>
      <div v-if="data" class="table-settings mb-4">
        <div class="d-flex align-items-center justify-content-between">
          <!-- <div v-if="data.status === 'SUBMITTED'" class> -->
          <div v-if="['SUBMITTED', 'PROCESSING'].includes(data.status)" class>
            <button
              type="button"
              class="btn btn-sm btn-success"
              @click="
                RequestConfirmation(
                  'Do you really want to approve this transaction?',
                  () => {
                    RequestConfirmation(
                      'Sure to approve this transaction? This is an irreversible action',
                      ApproveTransaction
                    );
                  }
                )
              "
            >Approve Transaction</button>
          </div>
          <div v-if="data.status === 'FAILED'" class>
            <button
              type="button"
              class="btn btn-sm btn-secondary text-nowrap"
              @click="
                RequestConfirmation(
                  'Do you really want to retry this transaction?',
                  () => {
                    RequestConfirmation(
                      'Sure to retry transaction? This is an irreversible action',
                      ProcessTransaction
                    );
                  }
                )
              "
            >Retry Transaction</button>
          </div>
          <div v-if="['SUBMITTED', 'FAILED', 'PROCESSING'].includes(data.status)" class>
            <button
              type="button"
              class="btn btn-sm btn-dark text-nowrap"
              @click="
                RequestConfirmation(
                  'Do you really want to manually certify this transaction?',
                  () => {
                    RequestConfirmation(
                      'Sure to set transaction manually? This is an irreversible action',
                      ManualTransaction
                    );
                  }
                )
              "
            >Manual Success</button>
          </div>
          <div class>
            <button
              type="button"
              class="btn btn-sm btn-danger text-nowrap"
              v-if="['SUBMITTED', 'FAILED', 'PROCESSING'].includes(data.status)"
              @click="
                RequestConfirmation(
                  'Do you really want to decline this withdrawal?',
                  () => {
                    RequestConfirmation(
                      'Sure to decline this transaction? This is an irreversible action',
                      () => {
                        sureConfirmDecline = true;
                      }
                    );
                  }
                )
              "
            >Decline Withdrawal</button>
          </div>
        </div>
      </div>
      <div v-if="data" class="row">
        <div v-if="userBalance" class="col-12 pb-5">
          <div class="text-right mx-2">
            <h4 class="text-gray">Total Balance</h4>
            <div class="d-lg-flex flex-row justify-content-end align-items-center py-1">
              <h5
                class="fw-bolder text-info border border-r ms-3 p-3"
                v-for="bal in userBalance"
                :key="bal"
              >
                <span class="mr-3">{{bal.Currency.code}}</span>
                {{ bal.balance }}
              </h5>
            </div>
          </div>

          <div class="card card-body border-light shadow-sm">
            <div class="text-center">
              <h4 class="text-gray">Created At</h4>
              <h5 class="fw-bolder">{{ data.created_at }}</h5>
            </div>

            <div class="row justify-content-center text-center py-3">
              <div class="col-6">
                <h4 class="text-gray">Status</h4>
                <h6>{{ data.status }}</h6>
              </div>
              <div class="col-6">
                <h4 class="text-gray">WalletId</h4>
                <h6>{{ data.fiat_wallet_id }}</h6>
              </div>
            </div>

            <div class="row text-center py-3">
              <div class="col-12 col-md-4">
                <h4 class="text-gray">UserId</h4>
                <router-link :to="`/users/${data.User.id}`">
                  <h6>{{ data.User.id }}</h6>
                </router-link>
              </div>
              <div class="col-12 col-md-4">
                <h4 class="text-gray">Email</h4>
                <router-link :to="`/users/${data.User.id}`">
                  <h6>{{ data.User.email }}</h6>
                </router-link>
              </div>
              <div class="col-12 col-md-4">
                <h4 class="text-gray">Name</h4>
                <router-link :to="`/users/${data.User.id}`">
                  <h6>
                    {{ data.User.Profile.first_name }}
                    {{ data.User.Profile.last_name }}
                  </h6>
                </router-link>
              </div>
            </div>

            <div class="row text-center py-3">
              <div class="col-12 col-md-6">
                <h4 class="text-gray">Comment</h4>
                <h6>{{ data.description ? data.description : "No Description" }}</h6>
              </div>
              <div class="col-12 col-md-6">
                <h4 class="text-gray">Remark</h4>
                <h6>{{ data.remark ? data.remark : "No Remark" }}</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 pb-5">
          <div class="card card-body border-light shadow-sm">
            <div class="text-center">
              <h6 class="text-gray">Amount Information</h6>
            </div>

            <div class="row text-center py-3">
              <div class="col-4">
                <h4 class="text-gray">Code</h4>
                <h6>{{ data.FiatCurrency.code }}</h6>
              </div>
              <div class="col-4">
                <h4 class="text-gray">Name</h4>
                <h6>{{ data.FiatCurrency.name }}</h6>
              </div>
              <div class="col-4">
                <h4 class="text-gray">Amount</h4>
                <h6>{{ data.amount }}</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 pb-5">
          <div class="card card-body border-light shadow-sm">
            <div class="text-center">
              <h6 class="text-gray">Credit Information</h6>
            </div>

            <div class="row justify-content-center text-center py-3">
              <div class="col-6">
                <h4 class="text-gray">Account Number</h4>
                <h6>{{ data.BankAccount.account_number }}</h6>
              </div>
              <div class="col-6">
                <h4 class="text-gray">Bank Code</h4>
                <h6>{{ data.BankAccount.bank_code }}</h6>
              </div>
            </div>
            <div class="row mt-4 gy-4 border justify-content-center text-center py-3">
              <div v-for="(value, key) in data.BankAccount.details" class="col-4" :key="key">
                <h4 class="text-gray">{{ key }}</h4>
                <h6>{{ value }}</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-5 mt-md-0">
          <div class="card card-body border-light shadow-sm">
            <div class="text-center">
              <h6 class="text-gray">Payment Attempts</h6>
            </div>

            <div class="card card-body border-light shadow-sm table-wrapper table-responsive pt-0">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Reference</th>
                    <th>FLW ID</th>
                    <th>Status</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(dt, index) in data.PaymentOutwards" :key="index">
                    <td>{{ dt.id }}</td>
                    <td>{{ dt.reference }}</td>
                    <td>{{ dt.flw_tx_id }}</td>
                    <td>{{ dt.status }}</td>
                    <td>{{ dt.created_at }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-12">
          <h1 class="text-danger text-center">No Data!</h1>
        </div>
      </div>
    </section>

    <div class="card-withdrawal-detail-response" v-if="sureConfirmDecline">
      <SecondaryModal :modalActive="sureConfirmDecline" @close="sureConfirmDecline = false">
        <div class style="width: 24rem">
          <div class="card-body d-flex justify-content-center align-items-center">
            <DefaultLoader :loading="loading">
              <div class>
                <h5 class="card-title my-3 text-center">Reason for Decline</h5>
                <!-- <button
                @click.prevent="customComment = !customComment"
                type="button"
                class="btn btn-secondary"
              >
                </button>-->
                <div>
                  <span v-if="customComment">Custom Response</span>
                  <span v-else>Automated Response</span>
                </div>

                <div class="mb-3 response-list">
                  <div class v-if="customComment">
                    <div class="my-2">
                      <textarea
                        class="form-control"
                        placeholder="Leave a comment here"
                        id="remark"
                        v-model="declineComment"
                        style="height: 100px; min-width: 100%"
                        required
                      ></textarea>
                      <!-- <label for="remark">Remark</label> -->
                    </div>

                    <div class="d-flex justify-content-between">
                      <button
                        @click="DeclineTransaction(declineComment)"
                        type="button"
                        class="btn btn-primary"
                      >Submit</button>
                    </div>
                  </div>
                  <div v-else>
                    <div
                      class="d-flex flex-column my-1"
                      v-for="remark in automatedResponses"
                      :key="remark.id"
                    >
                      <div
                        class="decline-response text-white btn p-1"
                        @click="DeclineTransaction(remark.response)"
                      >{{ remark.response }}</div>
                    </div>
                  </div>
                </div>

                <div class="d-flex align-items-center">
                  <button
                    @click="sureConfirmDecline = false"
                    type="button"
                    class="btn btn-light me-auto"
                  >Cancel</button>
                  <h6 class="cursor-pointer mx-4" @click.prevent="customComment = !customComment">
                    <span
                      v-if="!customComment"
                      class="text-underline text-info"
                    >Enter a custom response</span>
                    <span v-else class="text-underline text-info">Use Automated response</span>
                  </h6>
                </div>
              </div>
            </DefaultLoader>
          </div>
        </div>
      </SecondaryModal>
    </div>
  </HourglassLoader>
</template>

<script lang="ts">
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import { onBeforeMount, ref, defineComponent, inject, watchEffect } from "vue";
import SecondaryModal from "@/components/modal/SecondaryModal.vue";

import { apiGet, apiPost } from "@/api";
import { useRoute } from "vue-router";
import { Toast } from "@/utils/notify";
import Swal from "sweetalert2";

export default defineComponent({
  name: "WithdrawalDetail",
  components: {
    HourglassLoader,
    SecondaryModal,
  },
  setup() {
    const loading = ref(false);
    const data = ref(null);
    const userBalance = ref(null);

    const automatedResponses = ref([]);
    const customComment = ref(false);
    const declineComment = ref("");

    const RequestConfirmation = inject("RequestConfirmation");

    const route = useRoute();

    const sureConfirmDecline = ref(false);

    watchEffect(() => {
      document.body.classList.toggle(
        "no-scroll-on-decline-modal",
        sureConfirmDecline.value
      );
    });

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/admin/withdrawals/transactions/${route.params.transactionId}`
        );

        const getAutomatedResponses = await apiGet(
          `/admin/misc/automated/response`
        );

        const getTotalBalance = await apiGet(`/wallets/${route.params.userId}`);
        userBalance.value = getTotalBalance.data.data.fiatWallets;

        automatedResponses.value =
          getAutomatedResponses.data.data.automatedResponse;

        let transaction = response.data.data.transaction;
        data.value = transaction;

        // const userBalanceResponse = await apiGet(
        //   `/wallets/${transaction.User.id}`
        // );
        // userBalance.value = userBalanceResponse.data.data.fiatWallets[0];
        Toast.fire({
          icon: "success",
          title: "Transaction Detail Fetched Successfully",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    // const getUserBalance = async () => {
    //   loading.value = true;
    //   try {
    //     const response = await apiGet(`/wallets/${data.value?.User.id}`);
    //     userBalance.value = response.data.data.fiatWallets[0];
    //     // Toast.fire({
    //     //   icon: "success",
    //     //   title: "User Detail Fetched Successfully",
    //     // }).then();
    //   } catch (e) {
    //     Toast.fire({
    //       icon: "warning",
    //       title: "Unable to fetch data: " + e.message,
    //     }).then();
    //   }
    //   loading.value = false;
    // };

    const ApproveTransaction = async () => {
      loading.value = true;
      try {
        await apiPost(
          `/admin/withdrawals/transactions/${route.params.transactionId}/approve`
        );
        Toast.fire({
          icon: "success",
          title: "Transaction Approved Successfully",
        }).then();
        await RefreshData();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to approve: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    const ProcessTransaction = async () => {
      loading.value = true;
      try {
        await apiPost(
          `/admin/withdrawals/transactions/${route.params.transactionId}/retry`,
          { status: "PROCESSING" }
        );
        Toast.fire({
          icon: "info",
          title: "Transaction Processing",
        }).then();
        await RefreshData();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to approve: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    const ManualTransaction = async () => {
      loading.value = true;
      try {
        await apiPost(
          `/admin/withdrawals/transactions/${route.params.transactionId}/manual`,
          { status: "MANUAL" }
        );
        Toast.fire({
          icon: "info",
          title: "Transaction now marked successful",
        }).then();
        await RefreshData();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to approve manually: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    const DeclineTransaction = async (comment: string) => {
      // const { value: text } = await Swal.fire({
      //   input: "textarea",
      //   inputLabel: "Enter Reason/Comment for declining",
      //   inputPlaceholder: "Type your reason/comment here...",
      //   inputAttributes: {
      //     "aria-label": "Type your reason/comment here",
      //   },
      //   showCancelButton: true,
      // });

      // if (!text) {
      //   return;
      // }

      loading.value = true;
      try {
        await apiPost(
          `/admin/withdrawals/transactions/${route.params.transactionId}/decline`,
          { comments: comment, status: "FAILED" }
        );

        sureConfirmDecline.value = false;
        Toast.fire({
          icon: "success",
          title: "Transaction Declined Successfully",
        }).then();
        await RefreshData();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to decline: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    onBeforeMount(RefreshData);

    return {
      RefreshData,
      ApproveTransaction,
      DeclineTransaction,
      ProcessTransaction,
      ManualTransaction,
      userBalance,
      declineComment,
      sureConfirmDecline,
      automatedResponses,
      customComment,
      RequestConfirmation,
      route,
      data,
      loading,
    };
  },
});
</script>

<style >
.card-withdrawal-detail-response .question-border {
  border: 2px solid #f1c5f9;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  padding: 2rem;
}

.card-withdrawal-detail-response .decline-response {
  background-color: rgb(95, 116, 54);
}

.card-withdrawal-detail-response .response-list {
  height: 12rem !important;
  overflow-y: auto;
}

.card-withdrawal-detail-response .cursor-pointer {
  cursor: pointer;
}

.no-scroll-on-decline-modal {
  overflow: hidden;
}
</style>
